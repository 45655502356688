.App {
  position: relative;
  min-width: 900px;
  width: 100%;
  height: 100vh;
  text-align: left;
  background-color: #cccccc;
  font-size: 16px;
  color: #000;
}
.App h1 {
  font-size: 24px;
}
.App h2,
.App h3 {
  font-size: 20px;
  font-weight: normal;
}
.App ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}
.App li {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #333;
}
.App article {
  border: 1px solid #666;
  width: 90%;
  margin: 10px auto;
  padding: 10px;
}
.App label {
  display: inline-block;
  width: 100px;
  margin-right: 10px;
}
